<template>
  <div class="container">
    <van-nav-bar 
      :title="name" 
      fixed 
      @click-left="onClickLeft" 
      left-arrow />

    <div class="description" v-html="description"></div>

    <van-goods-action>
      <van-goods-action-icon icon="chat-o" text="客服" @click="contact" />
      <van-goods-action-button color="#E54320" type="danger" text="立即兑换" @click="duihuan" />
    </van-goods-action>

    <van-action-sheet v-model="zhifuType" title=" " style="height: ;">
      <div class="content">
        <van-tabs v-model="active">
          <van-tab 
            v-for="x in virtualGoodsPay" 
            :key="x" 
            :name="x" 
            :title="x=='1'?'金叶子':x=='2'?'驿马':x =='3'?'官凭':x =='4'?'现金':''"></van-tab>
        </van-tabs>

        <p class="content-zhifu">需支付</p>

        <div class="content-jine" v-if="active == '1'">
          <span class="content-jine-num">{{ goldLeaf }} </span>
          <span class="content-jine-type">金叶子</span>
        </div>

        <div class="content-jine" v-if="active == '2'">
          <span class="content-jine-num">{{ postHorse }} </span>
          <span class="content-jine-type">驿马</span>
        </div>

        <div class="content-jine" v-if="active == '3'">
          <span class="content-jine-num">{{ officialCertificate }} </span>
          <span class="content-jine-type">官凭</span>
        </div>

        <div class="content-jine" v-if="active == '4'">
          <span class="content-jine-fuhao">￥</span>
          <span class="content-jine-xianjin">{{ salesPrice }}</span>
        </div>

        <div style="margin-top: 20px;">
          <van-button round block color="#E54320" @click="confirmPayment">确认付款</van-button>
        </div>
      </div>
    </van-action-sheet>

    <van-action-sheet v-model="selectBoolean" title="选择账户">
      <div class="selectUser">
        <div class="paymentType">
          <div class="paymentType-item border-btn" v-for="x in shopJoinUser" :key="x.id"
            @click="paymentType(x.type, x.available, x.id)">
            <img style="width: 0.4865rem;" :src="x.icon" alt="">
            <span class="paymentType-item-type">{{ x.nameType }}</span>
            <span class="paymentType-item-hongmi">余额：{{ x.available + '金叶子' }}</span>
            <span>
              <van-icon v-show="x.type != type" name="checked" color="#b7b7b7" size="18" />
              <van-icon v-show="x.type == type" name="checked" color="#FB2B53" size="18" />
            </span>
          </div>
        </div>
        <div style="margin-top: 25px;">
          <van-button round block color="#E54320" @click="goldLeaf_zhifu">确认付款</van-button>
        </div>
      </div>
    </van-action-sheet>

    <van-action-sheet v-model="xianjinBoolean" title="现金支付">
      <div class="selectUser">
        <div>
          <div class="paymentType">
            <div class="paymentType-item border-btn" v-for="x in xianjin_zhifu" :key="x.id"
              @click="xianjinPaymentType(x.type, x.available, x.id)">
              <img style="width: 0.4865rem;" :src="x.icon" alt="">
              <span class="paymentType-item-type">{{ x.nameType }}</span>
              <span class="paymentType-item-hongmi"></span>
              <span>
                <van-icon v-show="x.type != xianjinType" name="checked" color="#b7b7b7" size="18" />
                <van-icon v-show="x.type == xianjinType" name="checked" color="#FB2B53" size="18" />
              </span>
            </div>
          </div>
        </div>

        <div style="margin-top: 25px;">
          <van-button round block color="#E54320" @click="salesPrice_zhifu">确认付款</van-button>
        </div>
      </div>
    </van-action-sheet>

    <loading :loading="loading" />

    <pay-success :paySuccessBoolean="paySuccessBoolean"/>
  </div>
</template>

<script>

import { getLuoma, isHonemi, getUserMoney, getGuishiOrder } from '@/api/flMall'
import { fuli_yundian_pay } from '@/common'
import { Toast } from 'vant'
import Loading from './Loading'
import PaySuccess from './PaySuccess'
import { contact } from "@/common"

export default {
  components: { Loading, PaySuccess },
  data () {
    return {
      active: '',
      description: '',
      zhifuType: false,
      virtualGoodsPay: [],
      goodsStatus: [],
      selectBoolean: false,
      xianjinBoolean: false,
      name: '',
      goldLeaf: 0,
      postHorse: 0,
      officialCertificate: 0,
      salesPrice: 0,
      shopJoinUser: [],
      xianjin_zhifu: [],
      type: '-1',
      xianjinType: '7',
      YiMa: 0, // 剩余驿马
      GuanPing: 0, // 剩余官凭
      loading: false,
      paySuccessBoolean: false
    }
  },

  watch: {
    zhifuType (Boolean) {
      if (!Boolean) {
        this.active = this.virtualGoodsPay[0]
      }
    },

    selectBoolean (Boolean) {
      if (!Boolean) {
        this.type = '-1'
      }
    },

    xianjinBoolean (Boolean) {
      if (!Boolean) {
        this.xianjinType = '7'
      }
    },

    paySuccessBoolean(Boolean) {
      if (!Boolean) {
        this.onClickLeft()
      }
    }
  },

  created () {
    this.$store.commit('setSession', this.$route.query.session)
  },

  mounted () {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
    window.paySuccess = this.paySuccess
    this.getPage()
    this.isHonemi()
    this.getUserMoney()
  },

  methods: {
    onClickLeft () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up")
      }
      if (isAndroid) {
        window.android.close()
      }
    },

    contact() {
      contact(this.imAccount)
    },

    paySuccess () {
      this.loading = false
      this.selectBoolean = false
      this.paySuccessBoolean = true
    },

    duihuan () {
      this.zhifuType = true
    },

    paymentType (type) {
      this.type = type
    },

    xianjinPaymentType (type) {
      this.xianjinType = type
    },

    confirmPayment () {
      this.zhifuType = false
      if (this.active == '1') {
        this.selectBoolean = true
      } else if (this.active == '2') {
        if (this.postHorse > this.YiMa) {
          Toast('驿马不足')
          return
        } else {
          const data = {
            virtualGoodsPay: '2',
            spuId: this.$route.query.id,
            advertisementId: this.$route.query.advertisementId || '',
            advertisementType:this.$route.query.advertisementType || '0'
          }
          this.getGuishiOrder(data)
        }
      }  else if (this.active == '3') {
        if (this.officialCertificate > this.GuanPing) {
          Toast('官凭不足')
          return
        } else {
          const data = {
            virtualGoodsPay: '3',
            spuId: this.$route.query.id,
            advertisementId: this.$route.query.advertisementId || '',
            advertisementType:this.$route.query.advertisementType || '0'
          }
          this.getGuishiOrder(data)
        }
      } else if (this.active == '4') {
        this.xianjinBoolean = true
      }
    },

    goldLeaf_zhifu () {
      if (this.type == '-1') {
        Toast('请选择金叶子方式')
        return
      }
      let obj = this.shopJoinUser.find(x => x.type == this.type)
      let available = Number(obj.available)
      let joinId = obj.id
      let spuId = this.$route.query.id

      if ( this.goldLeaf > available) {
        Toast('金叶子不足')
      } else {
        let data = {
          virtualGoodsPay: '1',
          joinId,
          spuId, 
          advertisementId: this.$route.query.advertisementId || '',
          advertisementType:this.$route.query.advertisementType || '0'
        }
        this.getGuishiOrder(data)
      }
    },

    salesPrice_zhifu () {
      const joinId = '6'
      const spuId = this.$route.query.id
      const advertisementId = this.$route.query.advertisementId || ''
      const advertisementType = this.$route.query.advertisementType || '0'
      // this.weixinPay(joinId, spuId, advertisementId, advertisementType)
      this.zhifubaoPay(joinId, spuId, advertisementId, advertisementType)
    },

    getGuishiOrder (data) {
      this.loading = true
      getGuishiOrder(data).then(res => {
        if (res.data.code === 0) {
          this.paySuccess()
        } else {
          Toast(res.data.message)
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },

    weixinPay (joinId, spuId, advertisementId, advertisementType) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.wechatPay.postMessage(`joinId=${joinId}&spuId=${spuId}&requesetType=18&session=${this.$route.query.session}&advertisementId=${advertisementId}&advertisementType=${advertisementType}`)
      }
      if (isAndroid) {
        window.android.wxpay(`joinId=${joinId}&spuId=${spuId}&requesetType=18&session=${this.$route.query.session}&advertisementId=${advertisementId}&advertisementType=${advertisementType}`)
      }
    },

    zhifubaoPay (joinId, spuId, advertisementId, advertisementType) {
      getGuishiOrder({ joinId, spuId, advertisementId, advertisementType, type: '2', virtualGoodsPay: '4' }).then(res => {
        let data = {
          data: res.data.data.body
        }
        if (res.data.code === 0) {
          fuli_yundian_pay(data, '2')
        } else {
          Toast(res.data.message)
        }
        this.xianjinBoolean = false
      }).catch(() => {
        Toast('服务繁忙，请稍后重试')
        this.xianjinBoolean = false
      })
    },

    getPage () {
      const id = this.$route.query.id
      getLuoma({id}).then(res => {
        if (res.data.code === 0) {
          this.name = res.data.data.name
          this.imAccount = res.data.data.imAccount
          this.goldLeaf = res.data.data.goldLeaf
          this.postHorse = res.data.data.postHorse
          this.officialCertificate = res.data.data.officialCertificate
          this.salesPrice = res.data.data.salesPrice
          this.description = res.data.data.description 
          this.virtualGoodsPay = res.data.data.virtualGoodsPay.split(',')
          this.active = this.virtualGoodsPay[0]
          this.goodsStatus = res.data.data.goodsStatus.split(',')
        }
      })
    },

    getUserMoney () {
      getUserMoney().then(res => {
        if (res.data.code === 0) {
          this.YiMa = res.data.data.YiMa
          this.GuanPing = res.data.data.GuanPing
        }
      })
    },

    isHonemi () {
      isHonemi().then(res =>  {
        if (res.data.code === 0) {
          res.data.data.shopJoinUser.forEach(x => {
            if (['0', '4'].includes(x.type)) {
              x.icon = require('./img/quanyi.png')
              x.nameType = '清澈淡远账户'//权益人
            } else if (['1'].includes(x.type)) {
              x.icon = require('./img/faqi.png')
              x.nameType = '超凡入圣账户'//发起人
            } else if (['5'].includes(x.type)) {
              x.icon = require('./img/pu.png')
              x.nameType = '普通帐户'
            } else if (['2'].includes(x.type)) {
              x.icon = require('./img/faqi.png')
              x.nameType = '扶危济困账户'//发起人
            } else if (['3'].includes(x.type)) {
              x.icon = require('./img/faqi.png')
              x.nameType = '莫问出身账户'//发起人
            }
          })

          this.shopJoinUser = res.data.data.shopJoinUser

          let xianjin_zhifu = [
            // {
            //   id: '6',
            //   type: '6',
            //   available: '6',
            //   cashWithdrawable: '6',
            //   nameType: '微信',
            //   icon: require('./img/weixin.png')
            // }
            {
              id: '7',
              type: '7',
              available: '7',
              cashWithdrawable: '7',
              nameType: '支付宝',
              icon: require('./img/zhifubao.png')
            }
          ]
          this.xianjin_zhifu = xianjin_zhifu
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  .van-nav-bar__left {
    padding: 0 15px;
  }
}
.container {
  height: 100%;
  box-sizing: border-box;
  background: url('./img/beijing.png') no-repeat;
  background-size: 100% 100%;
}
.content {
  padding: 25px;
  box-sizing: border-box;
  &-zhifu {
    text-align: center;
    margin: 20px 0;
  }
  &-jine {
    text-align: center;
    &-num {
      color: #E54320;
      font-size: 25px;
    }
    &-type {
      font-size: 18px;
    }
    &-fuhao {
      color: #E54320;
      font-size: 14px;
    }
    &-xianjin {
      color: #E54320;
      font-size: 25px;
    }
  }
}
.selectUser {
  padding: 25px;
  box-sizing: border-box;
  .paymentType {
    &-item {
      display: flex;
      align-items: center;
      padding: 0.3243rem 0;
      &-type {
        flex: 1;
        font-size: 0.3784rem;
        color: #333;
        margin-left: 0.2703rem;
      }
      &-hongmi {
        color: #F52556;
        font-size: 0.3784rem;
        margin-right: 0.2703rem;
      }
    }
    .border-btn {
      border-bottom: 1px solid #F6F6F6;
    }
  }
}
.zhifu_jine {
  margin: 10px 0 30px;
  display: flex;
  align-items: center;
  &-item {
    display: flex;
    align-items: center;
  }
}
.description {
  position: absolute;
  top: 56px;
  bottom: 70px;
  left: 20px;
  right: 20px;
  border-radius: 10px;
  background: #FEFAE9;
  padding: 20px;
  box-sizing: border-box;
  img { 
    vertical-align: middle;
  }
}
</style>